html,body {
  margin: 0;
}
.App {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-bottom: 5vh;;
}
.c-btn {
  display: flex;
  flex-direction: column;
  margin: 7vh auto;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.container {
  padding: 19vh 0 0 0;
  max-width: 800px;
  margin: 0 auto;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title>img {
  width: 200px;
}
.subtitle {
  margin: 0.8vh auto 0 auto;
  color: #0a0a0a;
  font-size: 1.9vh;
  padding: 2vh 3.5vh 2vh 3.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.btn {
  margin: 1vh 0;
  background: rgb(36, 36, 36);
  padding: 10px;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  border: none;
  transform: scale(1);
  animation: pulse 3s infinite;
  cursor: pointer;
  border-radius: 0.5vh;
  width: 300px;
}
.btn:hover, .btn:active {
  background: rgb(98, 98, 98);
  color: white;
}
.description {
  margin: 50vh 3.5vh 0 3.5vh;
  color: rgb(36, 36, 36);
  font-size: 1.8vh;
  padding: 2vh;
  background: #ffffffcc;
  border-radius: 1vh;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}
